@import url('https://rsms.me/inter/inter.css');


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in;
}


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior-y: none;
  background: #fff;
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #252525;
}

.status {
  position: absolute;
  bottom: 3vw;
  left: calc(50% - 6px);
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 2px;
  background: black;
  border-radius: 50%;
  z-index: 99;
}

.name {
  position: relative;
  line-height: 0.9em;
  letter-spacing: -0.01em;
  font-size: 12px;
}

.top-left {
    position: absolute;
    top: 3vw;
    left: 3vw;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: min(10vw, 5em);
    line-height: 0.9em;
  }
  
  .bottom-left {
    position: absolute;
    bottom: 3vw;
    left: 3vw;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: -0.01em;
    font-size: 18px;
    text-align: left;

  }

  .bottom-left a {
    text-decoration: none!important;
    color:#252525
  }
  
  .bottom-right {
    position: absolute;
    bottom: 3vw;
    right: 3vw;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 0.9em;
    background: #f5f5f5;
    padding:15px;
  }

  @media screen and (max-width: 1540px) {
    .bottom-right {
      bottom: 30vw
  }
}
  
  .left-middle {
    position: absolute;
    bottom: 50%;
    right: 3vw;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: -0.01em;
    font-size: 16px;
    transform: rotate(90deg) translate3d(50%, 0, 0);
    transform-origin: 100% 50%;
  }
  

  