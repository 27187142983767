.humberger {
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
    z-index: 1100;
  }
  
  .humberger .line {
    width: 50px;
    height: 2px;
    background-color: #000;
  }
  
  .active .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .active .line:nth-child(2) {
    opacity: 0;
  }
  
  .active .line:nth-child(3) {
    transform: rotate(-45deg) translate(9px, -9px);
  }
  
  .out_menu {
    height: 100vh;
    width: 450px;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    border-left: 1px solid #000;
    z-index: 1000;
    justify-content: center;
    transform: translate(100%);
  }
  
  .out_menu a {
    color: black;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 24px;
    width: 80%;
  }

  .link {
    margin:20px;
    font-size: 14px;
  }