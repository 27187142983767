html,
body,
#root {
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Kern Courant", sans-serif;
}

html {
  overflow-x: hidden;
}

canvas {
  cursor: grab;
  user-select: none;
  overflow: hidden;
}

.container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 1;
  animation: fade-1 2.5s ease both;
  animation-delay: 0.5s;

  position: absolute;
  top: 0;
}

.container-1 {
  width: 100vw;
  height: 100vh;
  background-color: #210101;
  z-index: 0;
  animation: fade-1 2s ease both;
  position: absolute;
  top: 0;
}


.no-link {
  color: black;
  text-decoration: none;
}

.footer {
  padding-bottom: 10vh;
}
.page {
  max-width: 1300px;
  margin: auto;
}

.df {
  display: flex;
}

.dfc {
  display: flex;
  flex-direction: column;
}

/* COLUMN */
.col-10 {
  width: 10%;
}

.col-30 {
  width: 30%;
}

.col-35 {
  width: 34%;
}

.col-45 {
  width: 45%;
}

.col-60 {
  width: 60%;
}

.aic {
  align-items: center;
}

.aifs {
  align-items: flex-start;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.hr-row {
  transform: rotate(90deg);
}

/* MARGIN */

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.m-12 {
  margin: 12px;
}

.m-16 {
  margin: 16px;
}

.m-20 {
  margin: 20px;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-0 {
  margin-right: 0;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-0 {
  margin-left: 0;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

/* PADDING */

/* HEIGHT */
.h-0 {
  height: 0;
}

.h-50vh {
  height: 50vh;
}

.h-100vh {
  height: 100vh;
}

.h-auto {
  height: auto;
}

/* WIDTH */

.w-0 {
  width: 0;
}

.w-1 {
  width: 1px;
}

.w-200 {
  width: 200px;
}

.w-30vw {
  width: 30vw;
}

.w-40vw {
  width: 40vw;
}

.w-50vw {
  width: 50vw;
}

.w-100vw {
  width: 100vw;
}

.w-auto {
  width: auto;
}

.w-48px {
  width: 48px;
}

.w-10vw {
  width: 10vw;
}

.w-450px {
  width: 450px;
}

.w-30vw {
  width: 30vw;
}

.w-50vw {
  width: 50vw;
}

.w-100 {
  width: 100%;
}
.w-100px {
  width: 100px;
}

.w-550px {
  width: 550px;
}

.max-w-400 {
  max-width: 400px;
}

.min-w-400 {
  min-width: 400px;
}

.max-w-45vw {
  max-width: 45vw;
}

.max-w-60 {
  max-width: 60%;
}

.w-100 {
  width: 100%;
}

.max-w-ft {
  max-width: fit-content;
}

.w-webkit {
  width: -webkit-fill-available;
}

/* GAP */
.g-4 {
  gap: 4px;
}

.g-8 {
  gap: 8px;
}

.g-12 {
  gap: 12px;
}

.g-16 {
  gap: 16px;
}

.g-20 {
  gap: 20px;
}

/* BACKGROUND COLOR */

.bg-white {
  background-color: white;
}

.bg-black {
  background-color: black;
}

.c-white {
  color: white;
}

.c-black {
  color: black;
}

/* Text transform */

.tt-u {
  text-transform: uppercase;
}

.tt-l {
  text-transform: lowercase;
}

/* FONT WEIGHT */

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

/* FONT SIZE */

.fs-8 {
  font-size: 8px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fs-40 {
  font-size: 40px;
}

.fs-48 {
  font-size: 48px;
}

@media screen and (max-width: 1540px) {
  .fs-48 {
    font-size: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .fs-40 {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .fs-48 {
    font-size: 18px;
  }
}

/* TRANSFORM */

.tr-90 {
  transform: rotate(90deg) translateY(-25%);
}

.tr--90 {
  transform: rotate(-90deg) translateX(-10px);
  white-space: nowrap;
}

/* SEPARATION */

.sep-row {
  height: auto;
  min-width: 1px;
  background-color: black;
}

/* Position */

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
  top: 20px;
}

/* Top */

.t-0 {
  top: 0;
}

.separation-right {
  width: -webkit-fill-available;
}

.row-line {
  width: auto;
  height: 1px;
  background-color: black;
}

.project-details{
  display:flex;
  flex-direction: column;
  gap:4px;
  padding: 20px 0;
}

.single-line {
  display: flex;
  flex-direction: row;
}

.single-line p {
  margin: 0 4px 0 0;
  line-height: 1.5em;
}

/* Line height */

.lh-150 {
  line-height: 150%;
}

/* Header top */

.sep {
  height: 50px;
}

@media screen and (max-width: 768px) {
  .page {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .pos-left {
    display: none;
  }

  .pr {
    flex-wrap: wrap;
  }
  .media-none {
    display: none;
  }
  .col-35 {
    width: 90%;
  }
  .col-60 {
    width: 90%;
  }
  .w-50vw {
    width: 90vw !important;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .cmd85 {
    width: 85%;
  }
  .row-line {
    width: 85%;
  }
  .w100IMP {
    width: 100% !important;
  }

  .mdr-0 {
    margin-right: 0;
  }
}
